#leftMenu h2 {
    width: 200px;
    text-align: center;
}

.logo {
    height: 40px !important;
    width: 40px !important;
}

.NavLogoMain {
    height: 40px !important;
}